import {
  platformAdapter,
  PlatformRecommendationsRequest,
  PlatformSemanticQueriesRequest,
  PlatformResult,
  recommendationsRequestSchema,
  resultSchema,
  semanticQueriesRequestSchema,
  experienceControlsResponseSchema,
  PlatformFacet,
  facetSchema
} from '@empathyco/x-adapter-platform';
import {
  EditableNumberRangeFacet,
  ExperienceControlsResponse,
  HierarchicalFacet,
  NumberRangeFacet,
  RecommendationsRequest,
  Result,
  SemanticQueriesRequest,
  SimpleFacet
} from '@empathyco/x-types';
import { mapPrice } from './utils/price.utils';

export const adapter = platformAdapter;

export interface JLJPlatformResult extends PlatformResult {
  description: string;
  brand: string;
  price: number;
  id: string;
  new: boolean;
  collection: string;
  isLetter: boolean;
}
interface JlJPlatformFacet extends PlatformFacet {
  label: string;
}

declare module '@empathyco/x-types' {
  export interface Result {
    description: string;
    brand: string;
    isNew: boolean;
    collection: string;
    isLetter: boolean;
  }

  export interface Facet {
    facet: string;
  }
}

resultSchema.$override<JLJPlatformResult, Partial<Result>>({
  description: 'description',
  brand: 'brand',
  images: ({ __images }) => (Array.isArray(__images) ? __images.reverse() : [__images]),
  price: mapPrice,
  id: 'id',
  isNew: 'new',
  collection: 'collection',
  isLetter: 'isLetter'
});

recommendationsRequestSchema.$override<
  RecommendationsRequest,
  Partial<PlatformRecommendationsRequest>
>({
  // TODO Top clicked demo endpoint breaks if it receives the scope parameter
  extraParams: ({ extraParams: { scope, ...extraParams } = {} }) => extraParams
});

semanticQueriesRequestSchema.$override<
  SemanticQueriesRequest,
  Partial<PlatformSemanticQueriesRequest>
>({
  extraParams: ({ extraParams }) => {
    return {
      extraParams,
      filter_ids: 'NOT_ALL_WORDS,NOT_PARTIAL'
    };
  }
});

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
experienceControlsResponseSchema.$override<
  Partial<ExperienceControlsResponse>,
  Partial<ExperienceControlsResponse>
>({
  controls: ({ controls }) => controls,
  events: {
    SemanticQueriesConfigProvided: {
      maxItemsToRequest: 'controls.semanticQueries.numberOfCarousels',
      resultsPerCarousel: 'controls.semanticQueries.resultsPerCarousels'
    }
  }
});

facetSchema.$override<
  JlJPlatformFacet,
  Partial<EditableNumberRangeFacet | HierarchicalFacet | NumberRangeFacet | SimpleFacet>
>({
  facet: 'facet',
  label: 'label'
});
